import './bootstrap';
import '../css/app.css';

// Import modules...
import { createApp, h } from "vue";
import {
    Head,
    Link,
    createInertiaApp,
} from "@inertiajs/vue3";
import globalFilters from "./Shared/globalProperties.js";
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import shadow from 'vue-shadow-dom'
import { i18nVue } from 'laravel-vue-i18n'
import * as Sentry from "@sentry/vue";
import Vapor from 'laravel-vapor';
import { router } from '@inertiajs/vue3';


window.Vapor = Vapor;
window.Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'MyGiesen';

createInertiaApp({
    title: (title) => title ? `${title} - ${appName}` : appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .component('InertiaHead', Head)
            .component('InertiaLink', Link)
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(shadow)
            .use(i18nVue, {
                fallbackLang: 'en',
                resolve: async lang => {
                    const langs = import.meta.glob('../lang/*.json');
                    return await langs[`../lang/${lang}.json`]();
                },
            })
            .mixin({ methods: { route, asset: window.Vapor.asset } });

        app.config.globalProperties.$filter = globalFilters;

        Sentry.init({
            app,
            dsn: import.meta.env.MODE === 'development' ? '' : 'https://29098035766b7c907d9ca4a9da201e11@sentry.giesen.com/3',
            environment: import.meta.env.MODE,
            tracesSampleRate: 1.0,
            trackComponents: true,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration()
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        app.mount(el)
    },
    progress: {
        color: '#4B5563',
    }
})

// InertiaProgress.init({ color: "#4B5563" });

if (import.meta.env.NODE_ENV === "production") {
    router.on("navigate", (event) => {
        gtag('event', 'page_view', {
            'page_location': event.detail.page.url
        });
        gtag("js", new Date());
        gtag("config", "G-LR853G2LLP");
    });
}
